import React, { useContext, useState } from 'react';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { useFormik } from 'formik';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Grid';
import { APButtonLoading, APInputSelectField, APTab, APTabs } from '@ap/design-system';
import { AppContext } from '../../../bootstrap/App';
import Dialog from '../Dialog/Dialog';
import TabPanel from '../TabPanel/TabPanel';
import { UserRegionalFormat, UserRegionalFormatList } from '../../../features/users/enums/UserRegionalFormat';
import { UserTheme, UserThemeList } from '../../../features/users/enums/UserTheme';
import useGetCurrentUser from '../../../features/users/services/useGetCurrentUser';
import useSetCurrentUserData from '../../../features/users/services/useSetCurrentUserData';
import { UserCrmChoice, UserCrmFormatList } from '../../../features/users/enums/UserCrmChoice';

const ValidationSchema = Yup.object().shape({
  theme: Yup.string().required('This field is required'),
  regionalFormat: Yup.string().required('This field is required'),
});

interface FormValues {
  theme: UserTheme;
  regionalFormat: UserRegionalFormat;
  crmChoice: UserCrmChoice;
}

interface Props {
  open: boolean;
  onClose: () => void;
}

function SettingsDialog(props: Props) {
  const { open, onClose } = props;

  const { setRegionalFormat, setDarkMode, setCrmChoice } = useContext(AppContext);
  const { setData, loading } = useSetCurrentUserData();
  const currentUser = useGetCurrentUser();

  const [tabValue, setTabValue] = useState(0);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  const initialValues: FormValues = {
    theme: currentUser.theme,
    regionalFormat: currentUser.regionalFormat,
    // TODO - SF delete crmChoice
    crmChoice: currentUser.crmChoice,
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: ValidationSchema,
    onSubmit: async (values: FormValues) => {
      const data = [];
      for (const [name, value] of Object.entries(values)) {
        data.push({
          name,
          value,
        });
      }

      await setData(data);

      setRegionalFormat(values.regionalFormat);
      setDarkMode(values.theme === UserTheme.Dark);
      setCrmChoice(values.crmChoice);

      toast.success('Settings successfully saved');
      onClose();
    },
  });

  const handleClose = () => {
    onClose();
    formik.resetForm();
  };

  const handleSave = async () => {
    await formik.submitForm();
  };

  const DialogActions = (
    <APButtonLoading autoFocus onClick={handleSave} loading={loading}>
      Save
    </APButtonLoading>
  );

  return (
    <Dialog id='settings' open={open} onClose={handleClose} title='Settings' actions={DialogActions} maxWidth='md'>
      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={7}>
          <Grid item xs={3}>
            <APTabs orientation='vertical' variant='scrollable' value={tabValue} onChange={handleTabChange}>
              <APTab id='ui' label='User interface' value={0} />
            </APTabs>
          </Grid>
          <Grid item xs={9}>
            <TabPanel value={tabValue} index={0}>
              <Stack spacing={4}>
                <APInputSelectField
                  id='theme'
                  label='Theme'
                  options={UserThemeList.map((theme) => ({ label: theme.name, value: theme.value }))}
                  value={formik.values.theme}
                  onChange={(event) => formik.setFieldValue('theme', event.target.value)}
                  error={formik.touched.theme && Boolean(formik.errors.theme)}
                  helperText={formik.touched.theme && formik.errors.theme ? formik.errors.theme : undefined}
                  required={true}
                />
                <APInputSelectField
                  id='regionalFormat'
                  label='Regional format'
                  options={UserRegionalFormatList.map((theme) => ({ label: theme.name, value: theme.value }))}
                  value={formik.values.regionalFormat}
                  onChange={(event) => formik.setFieldValue('regionalFormat', event.target.value)}
                  error={formik.touched.regionalFormat && Boolean(formik.errors.regionalFormat)}
                  helperText={
                    formik.touched.regionalFormat && formik.errors.regionalFormat
                      ? formik.errors.regionalFormat
                      : undefined
                  }
                  required={true}
                />
                <APInputSelectField
                  id='crm'
                  label='CRM choice'
                  options={UserCrmFormatList.map((theme) => ({ label: theme.name, value: theme.value }))}
                  value={formik.values.crmChoice}
                  onChange={(event) => formik.setFieldValue('crmChoice', event.target.value)}
                  error={formik.touched.crmChoice && Boolean(formik.errors.crmChoice)}
                  helperText={formik.touched.crmChoice && formik.errors.crmChoice ? formik.errors.crmChoice : undefined}
                  required={true}
                />
              </Stack>
            </TabPanel>
          </Grid>
        </Grid>
      </form>
    </Dialog>
  );
}

export default SettingsDialog;
