// TODO : SF - Delete the crm choice

export enum UserCrmChoice {
  SUGAR = 'sugar',
  SALESFORCE = 'salesforce',
}

export const UserCrmFormatList = [
  { name: 'Sugar', value: UserCrmChoice.SUGAR },
  { name: 'Salesforce', value: UserCrmChoice.SALESFORCE },
];
