import { useContext } from 'react';
import { AppContext } from '../../../bootstrap/App';
import CustomUserData from '../models/CustomUserData';
import { UserRegionalFormat } from '../enums/UserRegionalFormat';
import { UserRole } from '../enums/UserRole';
import { UserTheme } from '../enums/UserTheme';
import { MasterEventsView } from '../../masterEvents/models/MasterEvent';
import { UserCrmChoice } from '../enums/UserCrmChoice';

/**
 * Get the username of the current signed-in user
 */
function useGetCurrentUser() {
  const { realmUser } = useContext(AppContext);

  const data = realmUser ? (realmUser as unknown as CustomUserData) : undefined;

  return {
    name: data?.name ?? '',
    createdAt: data?.createdAt ?? new Date(),
    updatedAt: data?.updatedAt,
    realmUserId: data?.realmUserId ?? '',
    externalUserId: data?.externalUserId ?? '',
    theme: (data?.theme ?? UserTheme.Light) as UserTheme,
    regionalFormat: (data?.regionalFormat ?? UserRegionalFormat.ENGLISH_US) as UserRegionalFormat,
    // TODO : SF - Delete the crm choice
    crmChoice: (data?.crmChoice ?? UserCrmChoice.SUGAR) as UserCrmChoice,
    roles: (data?.roles ?? [UserRole.COLLABORATOR]) as UserRole[],
    alertShown: data?.alertShown ?? [],
    bookingCalendar: data?.bookingCalendar ?? undefined,
    welcomeWizardCompleted: data?.welcomeWizardCompleted ?? false,
    masterEventsView: data?.masterEventsView ?? MasterEventsView.Table,
  } as CustomUserData;
}

export default useGetCurrentUser;
